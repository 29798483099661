import {Component, OnInit, HostListener, ViewChild, ElementRef} from '@angular/core';
import {PageService} from 'common/_services/PageService';
import {AuthenticationService} from 'common/_services/authentication.service';
import {Router, ActivatedRoute} from '@angular/router';
import {AppDetailsComponent} from '../app-details.component/app-details.component';
import {Observable} from 'rxjs';
import {UserMenu} from '../../../../../common/_models/UserMenu';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

    menu$: Observable<UserMenu>;
    showPasswordChangeDialog = false;

    @ViewChild('appDetailsComponent', {static: true}) appDetailsComponent: AppDetailsComponent;
    @ViewChild('navBar', {static: true}) navBar;
    @ViewChild('profileImage') profileImage: ElementRef;

    constructor(
        public pageService: PageService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    get displayName(): string {
        return this.authenticationService.currentUserValue?.displayName ?? 'Account';
    }

    ngOnInit() {
        this.onResize(null);

        this.menu$ = this.authenticationService.getUserMenu()
            .pipe(
                map(
                    data => {
                        this.authenticationService.azureGraphPhoto()
                            .subscribe(image => {
                                const url = window.URL || window.webkitURL;
                                const blobUrl = url.createObjectURL(image);

                                this.profileImage.nativeElement.setAttribute('src', blobUrl);
                            });

                        return data;
                    })
            );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.pageService.smallWindowCheck(window.innerWidth);
    }

    toggleShowSideMenu(): boolean {
        return this.pageService.toggleSideBar();
    }

    logout() {
        this.authenticationService.logout(false);
    }

    showWhoAmIDetails() {
        this.appDetailsComponent.show = true;
    }

    navigate(url: string, event: MouseEvent) {
        this.router.navigate([url]);

        event.stopImmediatePropagation();
    }

    showSidebar() {
        if (!this.pageService.showSidebar) {
            setTimeout(() => {
                this.pageService.toggleSideBar(true); // This is done for css animations
            }, 1);
        }
    }

    showChangePassword() {
        this.showPasswordChangeDialog = true;
    }
}
